<template>
  <base-form
    v-slot="slotProps"
    :model="category"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    :mode="mode"
    model-name="Kategorie"
  >
    <el-form-item label="Name" prop="name">
      <el-input v-model="slotProps.model.name" v-focus></el-input>
    </el-form-item>
  </base-form>
</template>

<script>
import BaseForm from "@/components/forms/BaseForm"

export default {
  components: {
    BaseForm
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      rules: {
        name: [
          { required: true, message: "Bitte Namen angeben", trigger: "blur" }
        ]
      }
    }
  },
  created() {},
  methods: {}
}
</script>
