<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neue Kategorie">
      <download-category-form
        :category="category"
        submit-label="Neue Kategorie anlegen"
        :form-submitted="saveCategory"
        mode="new"
      ></download-category-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import DownloadCategoryForm from "@/components/forms/DownloadCategoryForm"
import DownloadCategoriesRepository from "@/repositories/download_categories_repository.js"

export default {
  metaInfo: {
    title: "Neue Kategorie"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    DownloadCategoryForm
  },
  data() {
    return {
      category: {}
    }
  },
  async created() {},
  methods: {
    async saveCategory(category) {
      await DownloadCategoriesRepository.create(category)
      this.$router.push({ name: "DownloadCategoriesPage" })
    }
  }
}
</script>
