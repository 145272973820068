import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/download_categories"

export default {
  async getAll() {
    let response = await Repository.get(resource, {
      params: { region_id: store.state.auth.chosenRegion }
    })
    return response.data.download_categories
  },
  async get(categoryID) {
    let response = await Repository.get(`${resource}/${categoryID}`)
    return response.data.download_category
  },
  async create(categoryData) {
    let response = await Repository.post(`${resource}`, {
      region_id: store.state.auth.chosenRegion,
      download_category: categoryData
    })
    return response.data
  },
  async update(categoryID, categoryData) {
    let response = await Repository.patch(`${resource}/${categoryID}`, {
      download_category: categoryData
    })
    return response.data
  },
  async destroy(categoryID) {
    let response = await Repository.delete(`${resource}/${categoryID}`)
    return response.data
  }
}
