export default {
  methods: {
    showFormUpdateSuccessMessage() {
      this.$message({
        message: `Änderungen wurden erfolgreich gespeichert.`,
        type: "success",
        showClose: true
      })
    },
    showFormCreateSuccessMessage(entity) {
      this.$message({
        message: `${entity} wurde erfolgreich angelegt.`,
        type: "success",
        showClose: true
      })
    },
    showCustomMessage(text, type) {
      this.$message({
        message: text,
        type: type,
        showClose: true
      })
    }
  }
}
